import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LayoutChangeEvent, NativeScrollEvent, NativeSyntheticEvent, RegisteredStyle, ViewStyle } from "react-native";
import { ScrollView as RNGHScrollView } from "react-native-gesture-handler";
import Animated, { useAnimatedStyle, useSharedValue, withDelay, withTiming } from "react-native-reanimated";
import { useI18nDate, useI18nMessage, useI18nTime } from "@lookiero/i18n-react";
import { theme } from "@lookiero/sty-psp-ui";
import { BoxPreviewWithFeedbackProjection } from "../../../../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { Tab } from "../../../routing/routes";
import { Body } from "../../layouts/body/Body";
import { Layout } from "../../layouts/layout/Layout";
import { STICKY_FOOTER_HEIGHT } from "../../layouts/layout/Layout.style";
import { Notification } from "../../molecules/notification/Notification";
import { BackToTopButton } from "../../organisms/backToTopButton/BackToTopButton";
import {
  BACK_TO_TOP_BUTTON_BOTTOM,
  BACK_TO_TOP_BUTTON_MARGIN_BOTTOM,
} from "../../organisms/backToTopButton/BackToTopButton.style";
import { DiscardSelection } from "../../organisms/discardSelection/DiscardSelection";
import { SelectionSummary } from "../../organisms/selectionSummary/SelectionSummary";
import { Footer } from "../footer/Footer";
import { IntroOnboarding } from "../introOnboarding/IntroOnboarding";
import { IntroSteps } from "../introSteps/IntroSteps";
import { style } from "./Items.style";

const { colorBgPrimaryLight, colorBgBase } = theme();

const BACKGROUND_COLOR_DELAY = 150;

interface ItemsProps {
  readonly children?: ReactNode;
  readonly boxPreviewWithFeedback: BoxPreviewWithFeedbackProjection;
  readonly tab: Tab;
  readonly onDiscard?: () => void;
  readonly onConfirmSelection?: () => void;
}
const Items: FC<ItemsProps> = ({ children, boxPreviewWithFeedback, tab, onDiscard, onConfirmSelection }) => {
  const backgroundColor = useSharedValue(colorBgBase);
  backgroundColor.value = tab === "looks" ? colorBgPrimaryLight : colorBgBase;
  const backgroundColorAnimatedStyle = useAnimatedStyle(
    () => ({ backgroundColor: withDelay(BACKGROUND_COLOR_DELAY, withTiming(backgroundColor.value)) }),
    [backgroundColor.value],
  );

  const expirationDate = useI18nDate({ value: boxPreviewWithFeedback.expiration, dateStyle: "long" });
  const expirationTime = useI18nTime({
    value: boxPreviewWithFeedback.expiration,
    hour: "numeric",
    minute: "numeric",
  });
  const timeframeAlert = useI18nMessage({
    prefix: PREFIX,
    id: I18nMessages.LANDING_PAGE_TIMEFRAME_ALERT,
    values: { expirationDate, expirationTime },
  });

  const chosenProductVariantsCount = useMemo(
    () => boxPreviewWithFeedback.productVariants.filter((productVariant) => productVariant.chosen).length,
    [boxPreviewWithFeedback.productVariants],
  );
  const hasChosenProductVariants = useMemo(() => chosenProductVariantsCount > 0, [chosenProductVariantsCount]);

  const handleOnDiscard = useCallback(() => {
    onDiscard?.();
    onConfirmSelection?.();
  }, [onConfirmSelection, onDiscard]);

  const scrollViewRef = useRef<RNGHScrollView>(null);
  const scrolledRef = useRef(false);
  const [childrenPosition, setChildrenPosition] = useState(0);
  const handleOnLayoutChildren = useCallback(
    (event: LayoutChangeEvent) => setChildrenPosition(event.nativeEvent.layout.y),
    [],
  );

  const [isSelectionSummaryVisible, setIsSelectionSummaryVisible] = useState(false);
  const [isBackToTopButtonVisible, setIsBackToTopButtonVisible] = useState(false);
  const handleOnPressBackToTopButton = useCallback(
    () => scrollViewRef.current?.scrollTo({ y: 0, animated: true }),
    [scrollViewRef],
  );

  const handleOnScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      scrolledRef.current = true;

      const scrollY = event.nativeEvent.contentOffset.y;

      setIsBackToTopButtonVisible(scrollY >= childrenPosition);

      if (hasChosenProductVariants) {
        setIsSelectionSummaryVisible(true);
      }
    },
    [childrenPosition, hasChosenProductVariants],
  );

  useEffect(() => {
    if (scrolledRef.current) {
      setIsSelectionSummaryVisible(hasChosenProductVariants);
    }
  }, [chosenProductVariantsCount, hasChosenProductVariants]);

  return (
    <Layout
      footer={<SelectionSummary count={chosenProductVariantsCount} onConfirm={onConfirmSelection} />}
      footerVisible={isSelectionSummaryVisible}
      scrollRef={scrollViewRef}
      onScroll={handleOnScroll}
    >
      <Notification style={style.notification} testID="timeframe-alert-notification">
        {timeframeAlert}
      </Notification>

      <Body style={{ row: style.bodyRow }}>
        {boxPreviewWithFeedback.isRecurrent ? <IntroSteps /> : <IntroOnboarding />}
      </Body>

      <Animated.View
        style={[style.content as RegisteredStyle<ViewStyle>, backgroundColorAnimatedStyle]}
        onLayout={handleOnLayoutChildren}
      >
        {children}

        {!hasChosenProductVariants && <DiscardSelection onDiscard={handleOnDiscard} />}
      </Animated.View>

      <BackToTopButton
        visible={isBackToTopButtonVisible}
        style={{
          bottom: hasChosenProductVariants
            ? STICKY_FOOTER_HEIGHT + BACK_TO_TOP_BUTTON_MARGIN_BOTTOM
            : BACK_TO_TOP_BUTTON_BOTTOM,
          marginBottom: BACK_TO_TOP_BUTTON_MARGIN_BOTTOM,
        }}
        onPress={handleOnPressBackToTopButton}
      />

      <Footer />
    </Layout>
  );
};

export { Items };
