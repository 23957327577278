import React, { FC, ReactNode } from "react";
import { StyleProp, ViewStyle } from "react-native";
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import { style } from "./Sticky.style";

interface StickyProps {
  readonly children: ReactNode;
  readonly visible: boolean;
  readonly testID?: string;
  readonly style?: StyleProp<ViewStyle>;
}

const Sticky: FC<StickyProps> = ({ children, visible, style: customStyle, testID }) => {
  const opacity = useSharedValue(visible ? 1 : 0);
  opacity.value = visible ? 1 : 0;
  const animatedStyle = useAnimatedStyle(() => ({ opacity: withTiming(opacity.value) }), [opacity.value]);

  return (
    <Animated.View
      pointerEvents={visible ? "auto" : "none"}
      style={[style.sticky, customStyle, animatedStyle]}
      testID={testID}
    >
      {children}
    </Animated.View>
  );
};

export { Sticky };
