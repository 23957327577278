import { StyleSheet, ViewStyle } from "react-native";

const STICKY_FOOTER_HEIGHT = 72;

const style = StyleSheet.create({
  children: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  scrollView: {
    flexGrow: 1,
  },
  // eslint-disable-next-line react-native/no-color-literals
  sticky: {
    backdropFilter: "blur(8px)",
    backgroundColor: "rgba(255, 255, 255, 0.80)",
    height: STICKY_FOOTER_HEIGHT,
    webkitBackdropFilter: "blur(8px)", // Safari required
  } as ViewStyle,
});

export { style, STICKY_FOOTER_HEIGHT };
