import React, { FC, memo } from "react";
import Animated, { FadeIn } from "react-native-reanimated";
import { ALIGN, Button, BUTTON_VARIANT, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { Body } from "../../layouts/body/Body";
import { style } from "./DiscardSelection.style";

const enteringTransition = FadeIn.delay(500);

interface DiscardSelectionProps {
  readonly onDiscard?: () => void;
}
const DiscardSelection: FC<DiscardSelectionProps> = ({ onDiscard }) => {
  const buttonContent = useI18nMessage({ prefix: PREFIX, id: I18nMessages.LANDING_PAGE_DISCARD_BUTTON });
  const explanation = useI18nMessage({ prefix: PREFIX, id: I18nMessages.LANDING_PAGE_DISCARD_EXPLANATION });

  return (
    <Animated.View entering={enteringTransition} testID="discard-selection">
      <Body style={{ row: style.bodyRow }}>
        <Button
          style={style.button}
          testID="discard-selection-button"
          variant={BUTTON_VARIANT.SECONDARY}
          onPress={onDiscard}
        >
          {buttonContent}
        </Button>

        <Text align={ALIGN.CENTER} level={2} testID="discard-selection-text" detail>
          {explanation}
        </Text>
      </Body>
    </Animated.View>
  );
};

const MemorizedDiscardSelection = memo(DiscardSelection);
export { MemorizedDiscardSelection as DiscardSelection };
